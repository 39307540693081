import auth from './auth'

export default class daoBase {
    static url = ""; //"http://localhost:8080";
    credentials = null;     

    constructor(credentials) {
        this.credentials = credentials; 
        if (credentials === undefined || credentials === null) {
             this.credentials = { user: auth.authUser, password: auth.authPassword };
        }
    }
    
    static genGuid() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    } 
}