import React, { useState } from 'react';
import { Upload, Modal, Input, Button, Row, Col, Switch } from 'antd';
import { DeleteOutlined, CaretLeftOutlined, CaretRightOutlined, StepBackwardOutlined, StepForwardOutlined} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import daoBase from '../api/daoBase';
import daoOffer from '../api/daoOffer';

export default function Uploader(props) {
    const [fileList, setFileList] = useState(props.fileList);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [imgeFileName, setImgeFileName] = useState("");
    const [imageDescription, setImageDescription] = useState("");
    const [mainImage, setMainImage] = useState(props.mainImage);
    const [isMainImage, setIsMainImage] = useState(false);
    const [reorderingNow, setReorderingNow] = useState(false);

    const url = daoBase.url + "/upload_offer_image?offerId="+props.offerId+"&token="+btoa(props.login+'|$|'+props.password);

    const onChange = ({ fileList: newFileList, file, event }) => {
        if (file.status === 'done') {
            for (let i in newFileList) {
                if (newFileList[i].uid == file.uid) {
                    newFileList[i].name = file.response.image;
                    newFileList[i].url = daoBase.url + "/get_offer_image?fileName=full."
                        + file.response.image
                        + "&offerId=" + props.offerId;
                }
            }
        }
        setFileList(newFileList);
    };

    const onRemove = (file) => {
        const { confirm } = Modal
        return new Promise((resolve, reject) => {
            confirm({
                title: 'Czy na pewno usunąć wybraną ilustrację?',
                okText: 'Tak',
                cancelText: 'Nie',
                onOk: () => {
                    const dao = new daoOffer();               
                    dao.deleteImage(file.name, props.offerId).then(data => { 
                        if (data.error != undefined) {
                            Modal.error({ title: 'Błąd', content: data.error });
                        } else {
                            if (file.name == mainImage) {
                                setMainImage(null);
                            }
                            // dodano
                            const newFileList = [];
                            for (let i in fileList) {
                                if (fileList[i].uid != file.uid) {
                                    newFileList.push(fileList[i]);
                                }
                            }
                            setFileList(newFileList);
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        Modal.error({ title: 'Błąd', content: error.Message });
                    });
                },
                onCancel: () => {
                    reject(true)
                }
            })
        })
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setIsMainImage(file.name == mainImage);
        setImgeFileName(file.name);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        setPreviewImage(file.url || file.preview);
        
        const dao = new daoOffer();             
            dao.getImageDescription(
                    file.name, 
                    props.offerId,
                    imageDescription
                ).then(data => { 
                if (data.error != undefined) {
                    Modal.error({ title: 'Błąd', content: data.error });
                } else {
                    setImageDescription(data.result);                    
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.Message });
            });
    };
    
    const handleCancel = () => setPreviewVisible(false);

    const onPreviewClick = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setIsMainImage(file.name == mainImage);
        setImgeFileName(file.name);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        setPreviewImage(file.url || file.preview);
        
        const dao = new daoOffer();             
            dao.getImageDescription(
                    file.name, 
                    props.offerId,
                    imageDescription
                ).then(data => { 
                if (data.error != undefined) {
                    Modal.error({ title: 'Błąd', content: data.error });
                } else {
                    setImageDescription(data.result);                    
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.Message });
            });
    };

    const onPreviewStoreClick = () => {
        const dao = new daoOffer();             
            dao.setImageDescription(
                    imgeFileName, 
                    props.offerId,
                    imageDescription == undefined || imageDescription == null ? '' : imageDescription
                ).then(data => { 
                if (data.error != undefined) {
                    Modal.error({ title: 'Błąd', content: data.error });
                } else {
                    setPreviewVisible(false);
                    setMainImage(imgeFileName)
                    props.callback({ mainImage: imgeFileName });
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.Message });
            });
    }

    const onPreviewClose = () => {
        setImageDescription("");
    }

    const onMoveLeft = (file) => {
        if (reorderingNow) {
            Modal.info({ title: 'Uwaga', content: 'Zaczekaj na zakończenie poprzedniej operacji przesuwania!' });
            return;
        }

        const newFileList = [];
        let moved = false;
        for (let i in fileList) {

            if (fileList[i].uid != file.uid) {
                newFileList.push(fileList[i]);
            } else {
                if (parseInt(i) > 0) {
                    const tmp = fileList[(parseInt(i)-1).toString()];                    
                    newFileList.push(tmp);
                    newFileList[parseInt(i)-1] = fileList[i];
                    moved = true; 
                } else {
                    newFileList.push(fileList[i]);
                }
            } 
        }

        if (moved) {
            setImageOrders(newFileList);
        }
    }

    const onMoveToBegin = (file) => {
        if (reorderingNow) {
            Modal.info({ title: 'Uwaga', content: 'Zaczekaj na zakończenie poprzedniej operacji przesuwania!' });
            return;
        }

        const newFileList = [];
        let moved = false;
        let curr = null;
        let ci = -1;

        for (let i in fileList) {
            if (fileList[i].uid == file.uid) {
                curr = fileList[i];
                ci = parseInt(i);
                break;
            }           
        }

        if (ci > 0) {
            const tmp = fileList[0];
            newFileList.push(curr);
            moved = true;

            for (let i in fileList) {
                if (fileList[i].uid != file.uid) {
                    newFileList.push(fileList[i]);
                }
            }
        }

        if (moved) {
            setImageOrders(newFileList);
        }
    }

    const onMoveRight = (file) => {
        if (reorderingNow) {
            Modal.info({ title: 'Uwaga', content: 'Zaczekaj na zakończenie poprzedniej operacji przesuwania!' });
            return;
        }

        const newFileList = [];
        let skipNext = false;
        let moved = false;
        for (let i in fileList) {
            if (skipNext) {
                skipNext = false;
                continue;
            }
            if (fileList[i].uid != file.uid) {
                newFileList.push(fileList[i]);
            } else {
                if (parseInt(i)+1 < fileList.length) {
                    newFileList.push(fileList[(parseInt(i)+1).toString()]);
                    newFileList.push(fileList[i]);
                    moved = true;
                    skipNext = true; 
                } else {
                    newFileList.push(fileList[i]);
                }
            } 
        }
        if (moved) {
            setImageOrders(newFileList);
        }
    }

    const onMoveToEnd = (file) => {
        if (reorderingNow) {
            Modal.info({ title: 'Uwaga', content: 'Zaczekaj na zakończenie poprzedniej operacji przesuwania!' });
            return;
        }
        
        const newFileList = [];
        let moved = false;
        let curr = null;
        let ci = -1;

        for (let i in fileList) {
            if (fileList[i].uid == file.uid) {
                curr = fileList[i];
                ci = parseInt(i);
                break;
            }           
        }

        if (ci+1 < fileList.length) {
            const tmp = fileList[fileList.length-1];
            
            for (let i in fileList) {
                if (fileList[i].uid != file.uid) {
                    newFileList.push(fileList[i]);
                }
            }

            newFileList.push(curr);
            moved = true;
        }

        if (moved) {
            setImageOrders(newFileList);
        }
    }

    const setImageOrders = (newFileList) => {
        setReorderingNow(true);

        const dao = new daoOffer();
        let imageOrders = "";
        for (let i in newFileList) {
            imageOrders += newFileList[i].name + '\r\n';
        } 
        dao.setImagesOrder(
                props.offerId,
                imageOrders
            ).then(data => { 
            
            setReorderingNow(false);
            if (data.error != undefined) {
                Modal.error({ title: 'Błąd', content: data.error });
            } else {
                setFileList(newFileList);
            }
        })
        .catch((error) => {
            setReorderingNow(false);
            Modal.error({ title: 'Błąd', content: error.Message });
        });
    } 

    return (
        <>
            <Upload
                name="offer_image"
                action={url}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={handlePreview}
                onRemove={onRemove}
                multiple={true}  
                accept="image/png, image/jpeg"
                itemRender={(node, file) => {                    
                   return( 
                   <div style={{ border: '1px solid #ededed', height: '104px', paddingLeft: '6px', paddingRight: '6px', paddingTop: '6px'}}>
                       <div style={{height: '70px', width: '90px'}}>
                       <img src={file.url} style={{maxHeight: '75px', maxWidth: '90px', cursor: 'pointer', margin: 'auto' }} onClick={(e) => onPreviewClick(file)} />
                       </div>
                       <div>
                       <a onClick={(e) => onRemove(file)} style={{float: 'right'}} title="Usuń" ><DeleteOutlined /></a>  
                       <a onClick={(e) => onMoveToBegin(file)} style={{float: 'left', marginRight: '2px'}} title="Przesuń na początek"><StepBackwardOutlined /></a>
                       <a onClick={(e) => onMoveLeft(file)} style={{float: 'left', marginRight: '2px'}} title="Przesuń w lewo"><CaretLeftOutlined /></a>
                       <a onClick={(e) => onMoveRight(file)} style={{float: 'left', marginRight: '2px'}} title="Przesuń w prawo"><CaretRightOutlined /></a> 
                       <a onClick={(e) => onMoveToEnd(file)} style={{float: 'left'}} title="Przesuń na koniec"><StepForwardOutlined /></a>
                       </div>
                   </div>)
                }}              
            >
                {fileList.length < 45 && '+ Upload'}
            </Upload>

            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
                afterClose={onPreviewClose}
                width={900}
                centered={true}
            >
                <Row>
                    <Col style={{ width: '100%', overflow: 'auto', maxHeight: 600 }} >
                        <img style={{width: '100%' }} src={previewImage} />
                    </Col>
                </Row>
                <Row style={{ paddingTop: 16 }}>
                    <Col style={{ width: 'calc(100% - 170px)', paddingRight: 12 }}><Input value={imageDescription} onChange={(e) => setImageDescription(e.target.value)}/></Col>                    
                    <Col style={{paddingTop: 3}}> <Switch checkedChildren="Główna" unCheckedChildren="Zwykła" checked={isMainImage} onChange={(e) => setIsMainImage(e)} /></Col>
                    <Col style={{paddingLeft: 12}}><Button style={{ width: '85px' }} onClick={onPreviewStoreClick}>Zapisz</Button></Col>
                </Row>
            </Modal>
        </>
    );
};