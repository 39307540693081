import postData from './dao'
import daoBase from './daoBase'

export default class daoOffer extends daoBase {
    getOffers(params) {
        return postData(
            daoBase.url+"/get_offers", { 
                credentials: this.credentials, 
                args: params 
            });
    }

    getOffer(id) {
        return postData(
            daoBase.url+"/get_offer", { 
                credentials: this.credentials, 
                id: id 
            });
    }

    getEstateTypes() {
        return postData(
            daoBase.url+"/get_estate_types", { 
                credentials: this.credentials, 
                args: null 
            });
    }

    getAdTypes() {
        return postData(
            daoBase.url+"/get_a_d_types", { 
                credentials: this.credentials, 
                args: null 
            });
    }
    
    getProvinces() {
        return postData(
            daoBase.url+"/get_provinces", { 
                credentials: this.credentials, 
                args: null 
            });
    }
    
    getPoviats(provinceId) {
        return postData(
            daoBase.url+"/get_poviats", { 
                credentials: this.credentials, 
                provinceId: provinceId 
            });
    }

    deleteImage(fileName, offerId) {
        return postData(
            daoBase.url+"/delete_offer_image", { 
                credentials: this.credentials, 
                fileName: fileName,
                offerId: offerId 
            });
    }
    
    setImagesOrder(offerId, imagesOrder) {
        return postData(
            daoBase.url+"/set_images_order", { 
                credentials: this.credentials, 
                offerId: offerId,
                imagesOrder: imagesOrder
            });
    }

    setImageDescription(fileName, offerId, description) {
        return postData(
            daoBase.url+"/set_image_description", { 
                credentials: this.credentials, 
                fileName: fileName,
                offerId: offerId,
                description: description 
            });
    }

    getImageDescription(fileName, offerId) {
        return postData(
            daoBase.url+"/get_image_description", { 
                credentials: this.credentials, 
                fileName: fileName,
                offerId: offerId,
            });
    }
 
    insertOffer(offer) {
        return postData(
            daoBase.url+"/insert_offer", { 
                credentials: this.credentials, 
                offer: offer,
            });
    }

    updateOffer(offer) {
        return postData(
            daoBase.url+"/update_offer", { 
                credentials: this.credentials, 
                offer: offer,
            });
    }

    deleteOffer(offerId) {
        return postData(
            daoBase.url+"/delete_offer", { 
                credentials: this.credentials, 
                offerId: offerId,
            });
    }
}