import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Checkbox, Row, Col, Select, InputNumber, Upload  } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import daoUser from '../api/daoUser';
import daoBase from '../api/daoBase';
import auth from '../api/auth';

export default function UserEditDialog(props) {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [uploadedImageName, setUploadedImageName] = useState();
    const uploadUrl = daoBase.url + "/upload_agent_image?token="+btoa(auth.authUser+'|$|'+auth.authPassword) + "&agentId="+props.userId;

    const roles = [
        {id: 1, name: "Administrator"},
        {id: 2, name: "Operator"},
        {id: 3, name: "Pracownik"}
    ];

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };

    useEffect(() => {  
        if (props.userId != -1) {
            const dao = new daoUser();
            dao.getUserById(props.userId)
                .then(data => { 
                    if (data.error != undefined) {
                        Modal.error({ title: 'Błąd', content: data.error });                       
                        props.onClose('Error');
                    } else if (data.result == null) {
                        Modal.error({ title: 'Błąd', content: "Brak definicji użytkownika o id: " + props.userId });
                        props.onClose('Error');
                    } else {
                        form.setFieldsValue({
                            active: data.result.active === 1,
                            login: data.result.login,
                            first_name: data.result.first_name,
                            last_name: data.result.last_name,
                            email: data.result.email,
                            phone1: data.result.phone1,
                            phone2: data.result.phone2,
                            license: data.result.license,
                            position1: data.result.position1,
                            position2: data.result.position2,
                            office_hours: data.result.office_hours,
                            is_agent: data.result.is_agent === 1,
                            role_id: data.result.role_id,
                            lp: data.result.lp
                        });
                        if (data.result.hasImage) {
                            setImageUrl(daoBase.url + `/get_agent_image?agentId=${props.userId}&q=${Date.now()}`);
                        }                        
                    }                
                })
                .catch((error) => {
                    Modal.error({ title: 'Błąd', content: error.Message }); 
                    props.onClose('Error');
                });
        }               
    }, [props.userId]);

    const handleOk = (e) => {
        props.onClose('handleOk');
    }
 
    const onCancel = (e) => {
        props.onClose('handleCancel');
    }
    
    const onStore = (values) =>  {
        const dao = new daoUser();
        const user = {
            id: props.userId,
            role_id: values.role_id,
            login: values.login,
            password: values.password,
            first_name: values.first_name,
            last_name: values.last_name,
            active: values.active === true ? 1 : 0,
            is_agent: values.is_agent === true ? 1 : 0,
            email: values.email,
            phone1: values.phone1,
            phone2: values.phone2,
            license: values.license,
            position1: values.position1,
            position2: values.position2,
            office_hours: values.office_hours == null ? '' : values.office_hours,
            lp: values.lp,
            image: uploadedImageName            
        };

        if (props.userId == -1) {            
            dao.insertUser(user).then(data => { 
                if (data.error != undefined) {
                    Modal.error({ title: 'Błąd', content: data.error });
                }  else {
                    props.onClose('Success');
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.Message });
            });
        } else {
            dao.updateUser(user).then(data => { 
                if (data.error != undefined) {
                    Modal.error({ title: 'Błąd', content: data.error });
                }  else {
                    props.onClose('Success');
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.Message });
            });
        }               
    }

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const  handleChange = info => {
        if (info.file.status === 'uploading') {
          setLoading(true);
          return;
        }
        if (info.file.status === 'done') {
            setUploadedImageName(info.file.response.image);
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, imageUrl => {
              setImageUrl(imageUrl);
              setLoading(false);
            }
          );
        }
      };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg';
        if (!isJpgOrPng) {
            Modal.error({ title: 'Błąd', content: "Możesz wysłać tylko pliki JPEG!" });
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            Modal.error({ title: 'Błąd', content: "Pliki nie mogą być większe niż 2MB!" });
        }
        return isJpgOrPng && isLt2M;
    }

    return (
        <Modal title="Użytkownik" visible='true' okText="Zapisz" width={800}
            cancelText="Anuluj"
            onCancel={onCancel}
            centered={true}
            maskClosable={false}        
            onOk={() => {
            form
                .validateFields()
                .then((values) => {                    
                    onStore(values);
                    //form.resetFields();
                })
                .catch((info) => {
                    console.log('Validate Failed:', info);
                });
            }}
        >
            <Form form={form} initialValues={{
                lp: 999,
            }}>
                <Row>
                    <Col span={12}>
                        <Form.Item label="Rola" name="role_id" labelCol={{span: 8}}
                            rules={[{ required: true, message: 'Wybierz rolę!'}]}>
                            <Select>
                            {roles.map(role => (
                                <Select.Option key={role.id} value={role.id}>{role.name}</Select.Option>
                            ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Login" name="login" labelCol={{span: 8}}
                            rules={[{ required: true, message: 'Podaj login!'}]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Hasło" name="password" labelCol={{span: 8}}>
                            <Input.Password />
                        </Form.Item>
                        <Form.Item label="Powtórz hasło" name="confirm_password" labelCol={{span: 8}}
                            rules={[
                                {
                                required: false,
                                message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item label="Aktywny" name="active" valuePropName='checked' labelCol={{span: 8}} >                            
                            <Checkbox />
                            
                        </Form.Item>
                        <Form.Item label="Agent" name="is_agent" valuePropName='checked' labelCol={{span: 8}} >                            
                            <Checkbox />
                        </Form.Item>
                        <Form.Item label="Zdjęcie" labelCol={{span: 8}}>
                        {<Upload
                            name="agent_image"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={uploadUrl}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Imię" name="first_name" labelCol={{span: 6}} 
                            rules={[{ required: true, message: 'Podaj imię!'}]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Nazwisko" name="last_name" labelCol={{span: 6}}
                            rules={[{ required: true, message: 'Podaj nazwisko!'}]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="E-mail" name="email" labelCol={{span: 6}}
                            rules={[{ required: true, message: 'Podaj e-mali!'}]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Telefon 1" name="phone1" labelCol={{span: 6}}
                            rules={[{ required: true, message: 'Podaj nr telefonu!'}]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label="Telefon 2" name="phone2" labelCol={{span: 6}} >                            
                            <Input />
                        </Form.Item>
                        <Form.Item label="L.p." name="lp" labelCol={{span: 6}} rules={[{ required: true, message: 'Podaj numer porządkowy!'}]} >                            
                            <InputNumber min={1} max={999} />
                        </Form.Item>
                        <Form.Item label="Stanowisko 1" name="position1" labelCol={{span: 6}}>                            
                            <Input />
                        </Form.Item>
                        <Form.Item label="Stanowisko 2" name="position2" labelCol={{span: 6}}>                            
                            <Input />
                        </Form.Item>                        
                    </Col> 
                    <Col span={24}>                        
                        <Form.Item label="Licencja" name="license" labelCol={{span: 4}}>                            
                            <Input />
                        </Form.Item>
                        <Form.Item label="Godziny pracy" name="office_hours" labelCol={{span: 4}} style={{marginBottom: 0}}>                            
                            <Input.TextArea rows={3} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
