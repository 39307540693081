import React, { useState } from 'react';
import auth from '../api/auth';


import {
    useLocation,
    Redirect,
} from 'react-router-dom';
import { Card, Row, Col, Form, Input, Button, Alert } from 'antd';
import {
    UserOutlined,
    LockOutlined
} from '@ant-design/icons';

export default function LoginPage(props) {
    const [
      redirectToReferrer,
      setRedirectToReferrer
    ] = React.useState(false)

    const { state } = useLocation()
    const [ error, setError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("xxxx");

    const login = (values) =>  {
        auth.authenticate((result) => {

            if (auth.isAuthenticated) {
                setRedirectToReferrer(true)
                props.onLogin(true);
                //return <Redirect to={'/protected'} />
            } else {
                setError(true);
                setErrorMessage(result.error);
                //alert(result.error);
            }
        }, 
        //"admin", "Office18" 
        values.username, values.password
        );
    }
  
    if (redirectToReferrer === true) {
      return <Redirect to={state?.from || '/'} />
    }
  
    return (
    <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
        <Col>
            <Card>     
                <Form onFinish={login} className="login-form" >
                    <Form.Item style={{ textAlign: 'center' }}>
                        <span className="login-form-title">ABA Nieruchomości</span>
                    </Form.Item>
                    <Form.Item
                        name="username"
                        rules={[
                        {
                            required: true,
                            message: 'Podaj Twój login!',
                        },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Login" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: 'Podaj Twoje hasło!',
                        },
                        ]}
                    >
                        <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                                />
                    </Form.Item>
                    
                    {(error) && <Form.Item><Alert message={errorMessage} type="error" /></Form.Item>}
                                                
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">Zaloguj się</Button>
                    </Form.Item>
                </Form>   
            </Card>
        </Col>
    </Row>
    )
  }