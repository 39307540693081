import React, { useState, useEffect } from 'react';
import { Upload, Modal, Row, Col } from 'antd';
import daoBase from '../api/daoBase';
import daoCertificate from '../api/daoCertificate';
import auth from '../api/auth';

export default function CertificatesPage(props) {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewTitle, setPreviewTitle] = useState("");
    const [previewImage, setPreviewImage] = useState("");
    const [imgeFileName, setImgeFileName] = useState("");
    const [fileList, setFileList] = useState([]);

    const url = daoBase.url + "/upload_cert_image?token="+btoa(auth.authUser+'|$|'+auth.authPassword);

    useEffect(() => {
        const dao = new daoCertificate();               
        dao.getImageList().then(data => { 
            if (data.error != undefined) {
                Modal.error({ title: 'Błąd', content: data.error });
            } else {
                let arr = [];
                for (let i in data.images) {
                    const img = data.images[i];
                    arr.push({
                        uid: `-${i}`,
                        name: img,
                        status: 'done',
                        url: daoBase.url+"/get_cert_image?fileName=full." + img,
                    });
                }
                setFileList(arr);
            }
        })
        .catch((error) => {
            Modal.error({ title: 'Błąd', content: error.Message });
        });
    }, [props.id]);

    const onChange = ({ fileList: newFileList, file, event }) => {
        if (file.status === 'done') {
            for (let i in newFileList) {
                if (newFileList[i].uid == file.uid) {
                    newFileList[i].name = file.response.image;
                    newFileList[i].url = daoBase.url + "/get_cert_image?fileName=full."
                        + file.response.image;
                }
            }
        }
        setFileList(newFileList);
    };

    const onRemove = (file) => {
        const { confirm } = Modal
        return new Promise((resolve, reject) => {
            confirm({
                title: 'Czy na pewno usunąć wybraną ilustrację?',
                okText: 'Tak',
                cancelText: 'Nie',
                onOk: () => {
                    const dao = new daoCertificate();               
                    dao.deleteImage(file.name).then(data => { 
                        if (data.error != undefined) {
                            Modal.error({ title: 'Błąd', content: data.error });
                        } else {
                            resolve(true);
                        }
                    })
                    .catch((error) => {
                        Modal.error({ title: 'Błąd', content: error.Message });
                    });
                },
                onCancel: () => {
                    reject(true)
                }
            })
        })
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setImgeFileName(file.name);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        setPreviewImage(file.url || file.preview);               
    };

    const handleCancel = () => setPreviewVisible(false);

   
    const onPreviewClose = () => {
    }

    return (
        <>
            <Upload
                name="cert_image"
                action={url}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={handlePreview}
                onRemove={onRemove}
                multiple={true}  
                accept="image/png, image/jpeg"
                //itemRender={(node, file) => {                    
                //   return( <img src={file.url} style={{maxHeight: '90px', maxWidth: '90px' }}/>)
                //}}              
            >
                {fileList.length < 45 && '+ Upload'}
            </Upload>

            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
                afterClose={onPreviewClose}
                width={900}
                centered={true}
            >
                <Row>
                    <Col style={{ width: '100%', overflow: 'auto', maxHeight: 600 }} >
                        <img style={{width: '100%' }} src={previewImage} />
                    </Col>
                </Row>                
            </Modal>
        </>
    );
};