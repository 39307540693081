import postData from './dao'
import daoBase from './daoBase'

class daoUser extends daoBase {
    getUserByLogin(login) {
        return postData(daoBase.url+"/get_user_by_login", { credentials: this.credentials, args: { login: login } });
    }

    getUserById(id) {
        return postData(daoBase.url+"/get_user_by_id", { credentials: this.credentials, args: { id: id } });
    }

    getUsers(params) {
        return postData(
            daoBase.url+"/get_users", { 
                credentials: this.credentials, 
                args: params 
            });
    }

    getAgents() {
        return postData(
            daoBase.url+"/get_agents", { 
                credentials: this.credentials, 
                args: null 
            });
    }

    insertUser(user) {
        return postData(daoBase.url+"/insert_user", { credentials: this.credentials, args: user });
    }

    updateUser(user) {
        return postData(daoBase.url+"/update_user", { credentials: this.credentials, args: user });
    }

    deleteUser(id) {
        return postData(daoBase.url+"/delete_user", { credentials: this.credentials, id: id });
    }
}

export default daoUser;