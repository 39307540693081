//import './App.css';
import React from 'react';
import { Layout, Menu, Space, SubMenu } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    HomeOutlined,
    LogoutOutlined,
    ShopOutlined,
    FileTextOutlined,
    FileOutlined  
} from '@ant-design/icons';
import {
    BrowserRouter as Router,
    Route,
    Link,
} from 'react-router-dom';

import auth from './api/auth';
import UsersPage from './pages/UsersPage';
import LoginPage from './pages/LoginPage';
import PrivateRoute from './components/PrivateRoute';
import ActivePageTitle from './components/ActivePageTitle';
import HomePage from './pages/HomePage';
import OffersPage from './pages/OffersPage';
import CmsPage from './pages/CmsPage';
import CertificatesPage from './pages/CertificatesPage';

const { Header, Sider, Content } = Layout;

class App extends React.Component {
    state = {
        collapsed: false,
        appTitle: 'ABA Nieruchomości',
    };

    toggle = () => {
        if (!this.state.collapsed) {
            this.setState({
                collapsed: !this.state.collapsed,
                appTitle: 'ABA'
            });
        } else {
            this.setState({
                collapsed: !this.state.collapsed,
                appTitle: 'ABA Nieruchomości',
            });
        }
    };

    render() {
        document.title = "ABA Nieruchomości";
        if (auth.isAuthenticated === true) {
            return (
                <Router>
                    <Layout style={{ minHeight: "100vh" }}>
                        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
                            <div className="app-title">{this.state.appTitle}</div>
                            <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                                <Menu.Item key="1" icon={<HomeOutlined />}>
                                    <Link to="/">Home</Link>
                                </Menu.Item>
                                <Menu.Item key="2" icon={<ShopOutlined />}>
                                    <Link to="/offers">Oferty</Link>
                                </Menu.Item>
                                {(auth.role < 3) && <Menu.SubMenu key="Cms" icon={<FileTextOutlined />} title="CMS">
                                    <Menu.Item key="Cms1" >
                                        <Link to="/cms/about">O firmie</Link>
                                    </Menu.Item>
                                    <Menu.Item key="Cms2">
                                        <Link to="/cms/offer">Oferta</Link>
                                    </Menu.Item>
                                    <Menu.Item key="Cms3">
                                        <Link to="/cms/valuation">Wycena</Link>
                                    </Menu.Item>
                                    <Menu.Item key="Cms4">
                                        <Link to="/cms/cooperation">Współpraca</Link>
                                    </Menu.Item>
                                    <Menu.Item key="Cms5">
                                        <Link to="/certificates">Certyfikaty</Link>
                                    </Menu.Item>
                                </Menu.SubMenu>}
                                {(auth.role < 3) && <Menu.Item key="3" icon={<UserOutlined />}>
                                    <Link to="/users">Użytkownicy</Link>
                                </Menu.Item>}
                                <Menu.Item key="4" icon={<LogoutOutlined />}>
                                    <a onClick={() => { auth.signout(() => { this.forceUpdate() }) }}>Wyloguj mnie</a>
                                </Menu.Item>
                            </Menu>
                        </Sider>
                        <Layout className="site-layout">
                            <Header className="site-layout-background" style={{ padding: 0, backgroundColor: "#efefef" }}>
                                <Space size="small">
                                    {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                        className: 'trigger',
                                        onClick: this.toggle,
                                    })}
                                    <ActivePageTitle />
                                </Space>
                            </Header>
                            <Content
                                className="site-layout-background"
                                style={{                                    
                                    padding: 24,
                                    paddingBottom: 0,
                                    minHeight: 280, 
                                }}
                            >
                                <Route exact path="/login">
                                    <LoginPage onLogin={() => this.forceUpdate()} />
                                </Route>
                                <PrivateRoute exact path='/'>
                                    <HomePage />
                                </PrivateRoute>
                                <PrivateRoute exact path='/users'>
                                    <UsersPage />
                                </PrivateRoute>
                                <PrivateRoute exact path='/offers'>
                                    <OffersPage />
                                </PrivateRoute>
                                <PrivateRoute exact path='/cms/:page'>
                                    <CmsPage />
                                </PrivateRoute>
                                <PrivateRoute exact path='/certificates'>
                                    <CertificatesPage id="1"/>
                                </PrivateRoute>
                            </Content>
                        </Layout>
                    </Layout>
                </Router>
            );
        } else {
            return (
                <Router>
                    <Layout className="layout">
                        <Content>
                            <div className="site-layout-content">
                                <LoginPage onLogin={() => this.forceUpdate()} />
                            </div>
                        </Content>
                    </Layout>
                    <Layout><Content></Content></Layout></Router>);
        }
    }
}

export default App;