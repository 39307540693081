import React from 'react';
import auth from '../api/auth'
import {
    Route,
    Redirect,
} from 'react-router-dom';


function PrivateRoute({ children, ...rest }) {
    return (
        <Route {...rest} render={({ location }) => {
        return (auth.isAuthenticated === true)
          ? children
          : <Redirect to={{
              pathname: '/login',
              state: { from: location }
            }} /> 
      }} />
    )
  }

  export default PrivateRoute;