import React, { useState, useEffect } from 'react';
import { Table, Input,  Button, Space, Modal, Typography, Select } from 'antd';
import { 
    CheckOutlined, SearchOutlined, EditOutlined, DeleteOutlined, 
    PlusCircleOutlined, ReloadOutlined,ClearOutlined  
} from '@ant-design/icons';
import daoOffer from '../api/daoOffer';
import daoUser from '../api/daoUser';
import OfferEditDialog from './OfferEditDialog';

import moment from 'moment';

class OffersPage extends React.Component {
    _estateType = -1;
    _adType = -1;
    _active = -1;
    _favourite = -1;
    _agentId = -1;
    _sortOrder = null;
    _sortColumn = null;
    _search = [];    

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Szukaj...`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 200, display: 'block' }}
                />
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#ee0000' : undefined }} />
        ),

        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
    });

    setVisibleColumns(vc) {
        const __columns = this.getColumns(true);                               
        for (let i in __columns) {
            if (__columns[i].visible === undefined) {
                continue;
            }

            __columns[i].visible = false;
            for (let j in vc) {
                if (vc[j] === __columns[i].title) {
                    __columns[i].visible = true;
                    break;
                }
            }
        }
    }

    _columns = [
        {
            title: 'Numer',
            visible: false,
            dataIndex: 'o__id_product',
            width: '70px',
            align: 'center',
            sorter: true,
            ellipsis: true,
            fixed: 'left',                
            ...this.getColumnSearchProps('o__id_product'),
        },
        {
            title: 'Akt.',
            visible: true,
            dataIndex: 'o__active',
            sorter: true,
            width: '50px',
            align: 'center',
            render: (cell) => { return ((cell == 1) ? <CheckOutlined /> : null) },
        },              
        {
            title: 'Rodzaj nieruchom.',
            visible: true,
            dataIndex: 'et__name',
            sorter: true,
            width: 190,
            ...this.getColumnSearchProps('et__name'),
            render: (cell) => { return (
                <Typography.Text ellipsis={true} style={{width: 170}}>{cell}</Typography.Text>)
            }
        },
        {
            title: 'Rodzaj ogłoszenia',
            visible: true,
            dataIndex: 'at__name',
            sorter: true,
            width: 170,
            ...this.getColumnSearchProps('at__name'),
            render: (cell) => { return (
                <Typography.Text ellipsis={true} style={{width: 170}}>{cell}</Typography.Text>)
            }
        },                     
        {
            title: 'Tytuł',
            visible: true,
            dataIndex: 'o__title',
            sorter: true,
            width: 500,
            ...this.getColumnSearchProps('o__title'),
            render: (cell) => { return (
                <Typography.Text ellipsis={true} style={{width: 500}}>{cell}</Typography.Text>)
            }                               
        },
        {
            title: 'Lokalizacja',
            visible: true,
            dataIndex: 'o__location1',
            sorter: true,
            width: 200,
            ...this.getColumnSearchProps('o__location1'),
            render: (cell) => { return (
                <Typography.Text ellipsis={true} style={{width: 200}}>{cell}</Typography.Text>)
            }
        },
        {
            title: 'Cena',
            visible: true,
            dataIndex: 'o__price',
            sorter: true,
            align: 'right',
            width: '100px',
            ellipsis: true,
            render: (cell) => { return (cell != null ? cell.toLocaleString('pl-PL'): null)}
        },
        {
            title: 'Powierzchnia',
            visible: true,
            dataIndex: 'o__area',
            sorter: true,
            align: 'right',
            width: '100px',
            ellipsis: true,
            render: (cell) => { return (cell != null ? cell.toLocaleString('pl-PL') : null)}
        },
        {
            title: 'J.m.',
            visible: true,
            dataIndex: 'o__area_display_unit',
            sorter: true,
            align: 'center',
            width: '50px',
            ellipsis: true,
        },   
        {
            title: 'Przypisana do',
            visible: true,
            dataIndex: 'u3__first_name$u3__last_name',
            sorter: true,
            ellipsis: true,
            width: 170,
            ...this.getColumnSearchProps('u3__first_name$u3__last_name'),
            render: (cell) => { return (
                <Typography.Text ellipsis={true} style={{width: 170}}>{cell}</Typography.Text>)
            }
        },
        {
            title: 'Wyr.',
            visible: true,
            dataIndex: 'o__favourite',
            sorter: true,
            width: '50px',
            align: 'center',
            render: (cell) => { return ((cell == 1) ? <CheckOutlined /> : null) },
        },                      
        {
            title: 'Data utworzenia',
            visible: true,
            dataIndex: 'o__date_add',
            sorter: true,
            width: '160px',
            align: 'center',
            ellipsis: true,
            render: (cell) => { return cell !== null ? (<span>{moment(new Date(cell)).format('YYYY-MM-DD HH:mm')}</span>) : null },
        },
        {
            title: 'Utworzył',
            visible: true,
            dataIndex: 'u1__first_name$u1__last_name',
            sorter: true,
            ellipsis: true,
            width: 150,
            ...this.getColumnSearchProps('u1__first_name$u1__last_name'),
            render: (cell) => { return (
                <Typography.Text ellipsis={true} style={{width: 150}}>{cell}</Typography.Text>)
            }
        }, 
        {
            title: 'Data zmiany',
            visible: true,
            dataIndex: 'o__date_upd',
            sorter: true,
            width: '160px',
            align: 'center',
            ellipsis: true,
            render: (cell) => { return cell !== null ? (<span>{moment(new Date(cell)).format('YYYY-MM-DD HH:mm')}</span>) : null },
        },
        {
            title: 'Zmienił',
            visible: true,
            dataIndex: 'u2__first_name$u2__last_name',
            sorter: true,
            ellipsis: true,
            width: 150,
            ...this.getColumnSearchProps('u2__first_name$u2__last_name'),
            render: (cell) => { return (
                <Typography.Text ellipsis={true} style={{width: 150}}>{cell}</Typography.Text>)
            }
        },   
        {
            title: '',
        },           
        {
            title: 'Akcja',
            key: 'action',
            fixed: 'right',
            align: 'center',
            width: 50,
            render: (text, record) => (
                <Space size="middle">
                    <a><EditOutlined onClick={(e) => { this.actionEdit(record.o__id_product, e); }} /></a>
                    <a><DeleteOutlined onClick={(e) => { this.actionDelete(record.o__id_product, e); }} /></a>
                </Space>
            ),
        },
    ];

    state = {
        totalCount: -1,
        data: [],
        pagination: {
            current: 1,
            pageSize: 16,
            showSizeChanger: false,
            position: ['bottomCenter'],
        },
        loading: false,
        search: [],
        sorter: null,
        searchUid: null,
        popup: true,
        estateType: -1,
        adType: -1,
        active: -1,
        favourite: -1,
        agentId: -1,
        showOfferEditDialog: false,
        currentOfferId: -1,
        visibleColumns: []
    };

    showTotal = (total) => {
        return `Wierszy: ${total}`;
    }

    estateTypes = [{ id_estate_type: -1, name: '- rodzaj nieruchomości -'}];
    adTypes = [{ id_ad_type: -1, name: '- rodzaj ogłoszenia -'}];
    activeStates = [
        { value: -1, name: '- aktywność -'},
        { value: 1, name: 'aktywne'},
        { value: 0, name: 'nieaktywne'},
    ];
    favouriteStates = [
        { value: -1, name: '- wyróżnienie -'},
        { value: 1, name: 'wyróżnione'},
        { value: 0, name: 'niewyróżnione'},
    ];
    belongsTo = [{ id: -1, full_name: '- przypisane do -'}];

    genSearchUid = (search, estateType, adType, active, favourite, agentId) => {
        let uid = 'estateType,' + estateType + ';adType,' + adType + ';active,' + active + 
                    ';favourite,' +favourite + ';agentId,' + agentId;
        if (search != undefined) {
            for (let i in search) {
                if (search[i].value != undefined && search[i].value != null) {
                    uid += search[i].field + ',' + search[i].value + ';';
                }
            }
        }
        return uid;
    }

    getColumns(all) {
        if (all) {
            return ( this._columns);
        } else {
            return this._columns.filter(col => col.visible === undefined || col.visible);
        }
    }

    componentDidMount() {
        this.getEstateTypes();
        this.getAdTypes();
        this.getAgents();

        const et = localStorage.getItem('OffersPage.estateType') != null ?  parseInt(localStorage.getItem('OffersPage.estateType')) : -1;                
        const at = localStorage.getItem('OffersPage.adType') != null ?  parseInt(localStorage.getItem('OffersPage.adType')) : -1;                
        this._active = localStorage.getItem('OffersPage.active') != null ?  parseInt(localStorage.getItem('OffersPage.active')) : -1;                
        this._favourite = localStorage.getItem('OffersPage.favourite') != null ?  parseInt(localStorage.getItem('OffersPage.favourite')) : -1;                
        const ag = localStorage.getItem('OffersPage.agentId') != null ?  parseInt(localStorage.getItem('OffersPage.agentId')) : -1;                
        this._sortOrder = localStorage.getItem('OffersPage.sortOrder') != null ?  localStorage.getItem('OffersPage.sortOrder') : null;                
        this._sortColumn = localStorage.getItem('OffersPage.sortColumn') != null ?  localStorage.getItem('OffersPage.sortColumn') : null;                
        
        const jsSearch = localStorage.getItem('OffersPage.search');
        if (jsSearch != null) {
            this._search = JSON.parse(jsSearch);
        }

        const jsVisibleColumns = localStorage.getItem('OffersPage.visibleColumns');
        let vc = [];
        if (jsVisibleColumns != null) {
            vc = JSON.parse(jsVisibleColumns);
        } else {
            for (let i in this._columns) {
                if (this._columns[i].visible !== undefined) {
                    vc.push(this._columns[i].title);
                }
            }
        }

        this.setVisibleColumns(vc);

        this.setState(
            {
                estateType: et,
                adType: at,
                active: this._active,
                favourite: this._favourite,
                agentId: ag, 
                search: this._search,
                visibleColumns: vc                                             
            }, 
            () => {
        
                const { pagination } = this.state;
                pagination.pageSize = Math.round((window.innerHeight - 270) / 39);

                let sorter = undefined;
                if (this._sortColumn != null) {
                    sorter = {};
                    sorter.field = this._sortColumn;
                    sorter.order = this._sortOrder;
                }

                this.fetch({ 
                    totalCount: -1, 
                    pagination, 
                    sorter,
                    search: this._search,
                    estateType: et, 
                    adType: at, 
                    active: this._active, 
                    favourite: this._favourite, 
                    agentId: ag });
            }
        );
        
        
    }

    estateTypeChange = (e) => {        
        const { pagination, search, sorter, adType, active, favourite, agentId } = this.state;
        pagination.current = 1;
        this.fetch({ totalCount: -1, pagination, search, sorter, estateType: e, adType, active, favourite, agentId });
        this.setState({ estateType: e, pagination: { current: 1 } });
        localStorage.setItem('OffersPage.estateType', e);
        this._estateType = e;
    }

    adTypeChange = (e) => {        
        const { pagination, search, sorter, estateType, active, favourite, agentId } = this.state;
        pagination.current = 1;
        this.fetch({ totalCount: -1, pagination, search, sorter, estateType, adType: e, active, favourite, agentId });
        this.setState({ adType: e, pagination: { current: 1 } });
        localStorage.setItem('OffersPage.adType', e);
        this._adType = e;
    }

    activeChange = (e) => {
        const { pagination, search, sorter, adType, estateType, favourite, agentId } = this.state;
        pagination.current = 1;
        this.fetch({ totalCount: -1, pagination, search, sorter, estateType, adType, active: e, favourite, agentId });
        this.setState({ active: e, pagination: { current: 1 } });
        localStorage.setItem('OffersPage.active', e);
        this._active = e;
    }

    favouriteChange = (e) => {
        const { pagination, search, sorter, adType, estateType, active, agentId } = this.state;
        pagination.current = 1;
        this.fetch({ totalCount: -1, pagination, search, sorter, estateType, adType, active, favourite: e, agentId });
        this.setState({ favourite: e, pagination: { current: 1 } });
        localStorage.setItem('OffersPage.favourite', e);
        this._favourite = e;
    }

    belongsToChange = (e) => {
        const { pagination, search, sorter, adType, estateType, active, favourite } = this.state;
        pagination.current = 1;
        this.fetch({ totalCount: -1, pagination, search, sorter, estateType, adType, active, favourite, agentId: e });
        this.setState({ agentId: e, pagination: { current: 1 } });
        localStorage.setItem('OffersPage.agentId', e);
        this._agentId = e;
    }
    

    handleTableChange = (pagination, filters, sorter) => {
        const { search, searchUid, totalCount, estateType, adType, active, favourite, agentId } = this.state;
        let _totalCount = totalCount;
        let su = this.genSearchUid(search, estateType, adType, active, favourite, agentId);
        if (su !== searchUid) {
            _totalCount = -1;
            this.setState({ searchUid: su });
        }

        let _sorter = undefined;
        if (sorter.order == undefined) {
            this._sortColumn = null;
            this._sortOrder = null;
            localStorage.removeItem('OffersPage.sortOrder');
            localStorage.removeItem('OffersPage.sortColumn'); 
        } else {
            _sorter = sorter;
            this._sortColumn = sorter.field;
            this._sortOrder = sorter.order;
            localStorage.setItem('OffersPage.sortOrder', sorter.order);
            localStorage.setItem('OffersPage.sortColumn', sorter.field);
        }

        pagination.pageSize = Math.round((window.innerHeight - 270) / 39);

        this.fetch({
            totalCount: _totalCount,
            pagination,
            sorter: _sorter,
            search,
            estateType,
            adType,
            active,
            favourite,
            agentId
        });
    };

    getEstateTypes = () => {
        const dao = new daoOffer();
        dao.getEstateTypes()
            .then(data => {
                if (data != null) {
                    this.estateTypes = this.estateTypes.concat(data);
                    this._estateType = this.state.estateType;
                } else {
                    Modal.error({ title: 'Błąd', content: data.error });
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.message });
            });
    }

    getAdTypes = () => {
        const dao = new daoOffer();
        dao.getAdTypes()
            .then(data => {
                if (data != null) {
                    this.adTypes = this.adTypes.concat(data);
                    this._adType = this.state.adType;
                } else {
                    Modal.error({ title: 'Błąd', content: data.error });
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.message });
            });
    }

    getAgents = () => {
        const dao = new daoUser();
        dao.getAgents()
            .then(data => {
                if (data != null) {
                    this.belongsTo = this.belongsTo.concat(data);
                    this._agentId = this.state.agentId;
                } else {                    
                    Modal.error({ title: 'Błąd', content: data.error });
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.message });
            });
    }

    fetch = (params = {}) => {
        this.setState({ loading: true });
        const dao = new daoOffer();
        dao.getOffers({
            totalCount: this.state.totalCount,
            ...params
        })
            .then(data => {               
                if (data != null && data.totalCount != undefined) {
                    this.setState({
                        totalCount: data.totalCount,
                        loading: false,
                        data: data.rows,
                        sorter: params.sorter != undefined ? params.sorter : null,
                        pagination: {
                            ...params.pagination,
                            total: data.totalCount,
                        }
                    });
                } else {
                    this.setState({loading: false});
                    Modal.error({ title: 'Błąd', content: data.error });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                Modal.error({ title: 'Błąd', content: error.message });
            });
    };

    

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        let found = false;
        let arr = [];
        const empty = selectedKeys[0] == null || selectedKeys[0] === '';
        for (let i in this.state.search) {
            if (this.state.search[i].field == dataIndex) {
                this.state.search[i].value = selectedKeys[0];
                found = true;
                break;
            }
        }

        if (!found) {
            this.state.search.push({ field: dataIndex, value: selectedKeys[0] });
        }

        for (let i in this.state.search) {
            if (this.state.search[i].value != null && this.state.search[i].value !== '') {
                arr.push(this.state.search[i]);
            }
        }

        if (arr.length == 0) {
            localStorage.removeItem('OffersPage.search');  
            this._search = null;
        } else {
            localStorage.setItem('OffersPage.search', JSON.stringify(arr));
            this._search = arr;
        }

        this.setState({ search: arr });
        confirm();
    };

    actionAdd = () => {
        this.setState({ showOfferEditDialog: true, currenOfferId: -1 });
    }

    actionEdit = (id, e) => {
        e.preventDefault();
        this.setState({ showOfferEditDialog: true, currenOfferId: id });
    }

    actionDelete = (id, e) => {
        e.preventDefault();
        Modal.confirm(
            { title: 'Pytanie', content: `Czy na pewno usnąć ofertę nr "${id}"?`,
                onOk: () => {
                    const dao = new daoOffer();               
                    dao.deleteOffer(id).then(data => { 
                        if (data.error != undefined) {
                            Modal.error({ title: 'Błąd', content: data.error });
                        } else {
                            this.actionReload();
                        }
                    })
                    .catch((error) => {
                        Modal.error({ title: 'Błąd', content: error.Message });
                    });
                },
                okText: 'Tak',
                cancelText: 'Nie'
            }
        );
    }

    actionReload = () => {
        const { pagination, search, sorter, estateType, adType, active, favourite, agentId } = this.state;
        this.fetch({ totalCount: -1, pagination, search, sorter, estateType, adType, active, favourite, agentId });
    }

    actionClearFilters = () => {
        localStorage.removeItem('OffersPage.estateType');
        localStorage.removeItem('OffersPage.adType');
        localStorage.removeItem('OffersPage.active');
        localStorage.removeItem('OffersPage.favourite');
        localStorage.removeItem('OffersPage.agentId');
        localStorage.removeItem('OffersPage.search');
        localStorage.removeItem('OffersPage.sortOrder');
        localStorage.removeItem('OffersPage.sortColumn');

        this._estateType = -1;
        this._adType = -1;
        this._active = -1;
        this._favourite = -1;
        this._agentId = -1;
        this._search = null;
        this._sortColumn = null;
        this._sortOrder = null;
        const { pagination } = this.state;
        pagination.current = 1;
        this.setState({
            estateType: -1,
            adType: -1,
            active: -1,
            favourite: -1,
            agentId: -1,  
            sorter: [],
            search: [],
            pagination: pagination,          
        }, () => {
            const { pagination, estateType, sorter, search, adType, active, favourite, agentId } = this.state;
            this.fetch({ totalCount: -1, pagination, sorter, search, estateType, adType, active, favourite, agentId });    
        });
    }

    onCloseOfferEditDialog = (e) => {
        this.setState({showOfferEditDialog: false});
        if (e === "Success") {
            this.actionReload();
        }
    }

    render() {
        const { data, pagination, loading, search, sorter } = this.state;
        pagination.showTotal = this.showTotal;
        
        let columns = this.getColumns(false);
        
        if (this._sortColumn != null) {
            for (let i in columns) {
                if (columns[i].dataIndex === this._sortColumn) {
                    columns[i].sortOrder = this._sortOrder;
                } else {
                    columns[i].sortOrder = null;
                }
            }
            //this._sortColumn = null;
        }

        if (this._search != null) {
            for (let i in columns) {
                for (let s in this._search) {
                    if (columns[i].dataIndex === this._search[s].field &&
                        this._search[s].value != undefined) {
                        columns[i].filteredValue = this._search[s].value.split();                        
                        break;
                    }
                }
            }
           // this._search = null;
        }

        if (sorter == undefined || sorter == null || sorter.length == 0) {
            for (let i in columns) {
                columns[i].sortOrder = false;
            }
        }
              
        if (search == undefined || search == null || search.length == 0) {
            for (let i in columns) {
                columns[i].filteredValue = null;
            }
        }

        let allCols = [];
        let tmpCols = this.getColumns(true);
        for (let i in tmpCols) {
            if (tmpCols[i].visible !== undefined) {
                allCols.push(<Select.Option key={tmpCols[i].title}>{tmpCols[i].title}</Select.Option>);                
            } 
        }

        return (
            <div>
                <div style={{ float: 'right', paddingBottom: '12px' }}>
                    <Space size="small">
                        <Button type="primary" icon={<PlusCircleOutlined />} onClick={this.actionAdd}>Dodaj</Button>
                    </Space>
                </div>
                <div style={{ float: 'left', paddingBottom: '12px' }}>
                    <Space size="small">
                        <Select style={{width: 210}} defaultValue={-1} value={this._estateType} onChange={this.estateTypeChange}
                            showSearch 
                            optionFilterProp="children" 
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>                       
                            {this.estateTypes.map(estateType => (
                                <Select.Option key={estateType.id_estate_type} value={estateType.id_estate_type}>{estateType.name}</Select.Option>
                            ))}
                        </Select>
                        <Select style={{width: 180}} defaultValue={-1} value={this._adType} onChange={this.adTypeChange}
                            showSearch 
                            optionFilterProp="children" 
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                            {this.adTypes.map(adType => (
                                <Select.Option key={adType.id_ad_type} value={adType.id_ad_type}>{adType.name}</Select.Option>
                            ))}
                        </Select>
                        <Select style={{width: 130}} defaultValue={-1} value={this._active} onChange={this.activeChange}
                            showSearch 
                            optionFilterProp="children" 
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                            {this.activeStates.map(active => (
                                <Select.Option key={active.value} value={active.value}>{active.name}</Select.Option>
                            ))}
                        </Select>
                        <Select style={{width: 140}} defaultValue={-1} value={this._favourite} onChange={this.favouriteChange}
                            showSearch 
                            optionFilterProp="children" 
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                            {this.favouriteStates.map(favourite => (
                                <Select.Option key={favourite.value} value={favourite.value}>{favourite.name}</Select.Option>
                            ))}
                        </Select> 
                        <Select style={{width: 220}} defaultValue={-1} value={this._agentId} onChange={this.belongsToChange}
                            showSearch 
                            optionFilterProp="children" 
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                            {this.belongsTo.map(agent => (
                                <Select.Option key={agent.id} value={agent.id}>{agent.full_name}</Select.Option>
                            ))}
                        </Select>   
                                                                  
                        <Button type="default" icon={<ReloadOutlined />} onClick={this.actionReload}>Odśwież</Button>
                        <Button type="default" icon={<ClearOutlined />} onClick={this.actionClearFilters}>Resetuj</Button>
                        <Select
                            mode="multiple"
                            style={{ width: 155 }}
                            placeholder="Widoczne kolumny"
                            value={this.state.visibleColumns}
                            maxTagCount={0}
                            maxTagPlaceholder="Widoczne kolumny"
                            onChange={(vc) => {  
                                this.setVisibleColumns(vc);
                                this.setState( { visibleColumns: vc });
                                localStorage.setItem('OffersPage.visibleColumns', JSON.stringify(vc));
                                //this.actionReload();                           
                            }}
                            >
                            {allCols}
                        </Select>                      
                    </Space>
                </div>
                <div style={{ clear: 'both' }}>
                    <Table
                        style={{cursor: 'pointer'}}
                        size='small'
                        bordered
                        columns={this.getColumns(false)}
                        rowKey={record => record.o__id_product}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={this.handleTableChange}
                        scroll={{ x: 'max-content' }}
                        onRow={(record, rowIndex) => {
                            return {
                              onDoubleClick: (event) => {
                                  this.setState(
                                      {
                                        showOfferEditDialog: true,
                                        currenOfferId: record.o__id_product
                                      }
                                  );
                                }, // double click row
                            };
                          }}                    
                    />
                </div>
                {this.state.showOfferEditDialog && <OfferEditDialog onClose={this.onCloseOfferEditDialog} offerId={this.state.currenOfferId} />}
            </div>
        );
    }
}

export default OffersPage;