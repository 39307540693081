import postData from './dao'
import daoBase from './daoBase'

export default class daoCertificate extends daoBase {
    deleteImage(fileName, offerId) {
        return postData(
            daoBase.url+"/delete_cert_image", { 
                credentials: this.credentials, 
                fileName: fileName
            });
    }

    getImageList() {
        return postData(
            daoBase.url+"/get_cert_image_list", { 
                credentials: this.credentials
            });
    }
}