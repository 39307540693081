import React from 'react';
import { useLocation } from 'react-router-dom';

export default function ActivePageTitle() {
    const location = useLocation();
    let title = '';
    switch (location.pathname) {
        case '/' : title = 'Home'; break;
        case '/offers' : title = 'Oferty'; break;
        case '/users' : title = 'Użytkownicy'; break;
        case '/cms/about' : title = 'CMS / O firmie'; break;
        case '/cms/cooperation' : title = 'CMS / Współpraca'; break;
        case '/cms/offer' : title = 'CMS / Oferta'; break;
        case '/cms/valuation' : title = 'CMS / Wycena'; break;
        case '/certificates' : title = 'CMS / Certyfikaty'; break;
        default : title = location.pathname;
    }   

    return (
        <div className='activePageTitle'>{title}</div>
    );
}