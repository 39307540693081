import React from 'react';
import { Table, Input, Button, Space, Modal } from 'antd';
import { CheckOutlined, SearchOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import daoUser from '../api/daoUser';
import moment from 'moment';
import UserEditDialog from './UserEditDialog';

class UsersPage extends React.Component {
    state = {
        totalCount: -1,
        data: [],
        pagination: {
            current: 1,
            pageSize: 15,
            showSizeChanger: false,
            position: ['bottomCenter'],
        },
        loading: false,
        search: [],
        sorter: null,
        searchUid: null,
        popup: true,
        showUserEditDialog: false,
        currentUserId: -1,
    };

    showTotal = (total) => {
        return `Wierszy: ${total}`;
    }

    genSearchUid = (search) => {
        let uid = '';
        if (search != undefined) {
            for (let i in search) {
                if (search[i].value != undefined && search[i].value != null) {
                    uid += search[i].field + ',' + search[i].value + ';';
                }
            }
        }
        return uid;
    }

    componentDidMount() {
        const { pagination } = this.state;
        pagination.pageSize = Math.round((window.innerHeight - 270) / 39);
        this.fetch({ totalCount: -1, pagination });
    }

    handleTableChange = (pagination, filters, sorter) => {
        const { search, searchUid, totalCount } = this.state;
        let _totalCount = totalCount;
        let su = this.genSearchUid(search);
        if (su !== searchUid) {
            _totalCount = -1;
            this.setState({ searchUid: su });
        }
        
        pagination.pageSize = Math.round((window.innerHeight - 270) / 39);

        this.fetch({
            totalCount: _totalCount,
            sorter,
            pagination,
            search
        });
    };


    fetch = (params = {}) => {
        this.setState({ loading: true });
        const dao = new daoUser();
        dao.getUsers({
            totalCount: this.state.totalCount,
            ...params
        })
            .then(data => {
                if (data != null && data.totalCount != undefined) {
                    this.setState({
                        totalCount: data.totalCount,
                        loading: false,
                        data: data.rows,
                        sorter: params.sorter != undefined ? params.sorter : null,
                        pagination: {
                            ...params.pagination,
                            total: data.totalCount,
                        }
                    });
                } else {
                    this.setState({loading: false});
                    Modal.error({ title: 'Błąd', content: data.error });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                Modal.error({ title: 'Błąd', content: error.message });
            });
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Szukaj...`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined type="search" style={{ color: filtered ? '#ee0000' : undefined }} />
        ),

        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        let found = false;
        let arr = [];
        const empty = selectedKeys[0] == null || selectedKeys[0] === '';
        for (let i in this.state.search) {
            if (this.state.search[i].field == dataIndex) {
                this.state.search[i].value = selectedKeys[0];
                found = true;
                break;
            }
        }

        if (!found) {
            this.state.search.push({ field: dataIndex, value: selectedKeys[0] });
        }

        for (let i in this.state.search) {
            if (this.state.search[i].value != null && this.state.search[i].value !== '') {
                arr.push(this.state.search[i]);
            }
        }

        this.setState({ search: arr });
        confirm();
    };

    actionAdd = () => {
        this.setState({ showUserEditDialog: true, currentUserId: -1 });
    }

    actionEdit = (id, e) => {
        e.preventDefault();
        this.setState({ showUserEditDialog: true, currentUserId: id });
    }

    actionDelete = (id, name, e) => {
        e.preventDefault();
        Modal.confirm(
            { title: 'Pytanie', content: `Czy na pewno usnąć użytkownika o loginie "${name}"?`,
                onOk: () => {
                    const dao = new daoUser();               
                    dao.deleteUser(id).then(data => { 
                        if (data.error != undefined) {
                            Modal.error({ title: 'Błąd', content: data.error });
                        } else {
                            this.actionReload();
                        }
                    })
                    .catch((error) => {
                        Modal.error({ title: 'Błąd', content: error.Message });
                    });
                },
                okText: 'Tak',
                cancelText: 'Nie'
            }
        );
    }

    

    actionReload = () => {
        const { pagination, search, sorter } = this.state;
        this.fetch({ totalCount: -1, pagination, search, sorter });
    }

    onCloseUserEditDialog = (e) => {
        this.setState({showUserEditDialog: false});
        if (e === "Success") {
            this.actionReload();
        }
    }

    render() {
        const { data, pagination, loading } = this.state;
        pagination.showTotal = this.showTotal;
        
        const columns = [
            {
                title: 'Login',
                dataIndex: 'u__login',
                sorter: true,
                ...this.getColumnSearchProps('u__login'),
            },
            {
                title: 'Imię',
                dataIndex: 'u__first_name',
                sorter: true,
                width: '200px',
                ...this.getColumnSearchProps('u__first_name'),
            },
            {
                title: 'Nazwisko',
                dataIndex: 'u__last_name',
                sorter: true,
                width: '200px',
                ...this.getColumnSearchProps('u__last_name'),
            },
            {
                title: 'E-mail',
                dataIndex: 'u__email',
                sorter: true,
                width: '300px',
                ...this.getColumnSearchProps('u__email'),
            },
            {
                title: 'Rola',
                dataIndex: 'r__name',
                sorter: true,
                width: '150px',
                ...this.getColumnSearchProps('r__name'),
            },
            {
                title: 'Aktywny',
                dataIndex: 'u__active',
                sorter: false,
                width: '60px',
                align: 'center',
                render: (cell) => { return ((cell == 1) ? <CheckOutlined /> : null) },
            },
            {
                title: 'Agent',
                dataIndex: 'u__is_agent',
                sorter: false,
                width: '60px',
                align: 'center',
                render: (cell) => { return ((cell == 1) ? <CheckOutlined /> : null) },
            },
            {
                title: 'Data utworzenia',
                dataIndex: 'u__c_date',
                sorter: true,
                width: '160px',
                align: 'center',
                render: (cell) => { return cell !== null ? (<span>{moment(new Date(cell)).format('YYYY-MM-DD HH:mm')}</span>) : null },
            },
            {
                title: 'Data zmiany',
                dataIndex: 'u__pc_date',
                sorter: true,
                width: '160px',
                align: 'center',
                render: (cell) => { return cell !== null ? (<span>{moment(new Date(cell)).format('YYYY-MM-DD HH:mm')}</span>) : null },
            },
            {
                title: 'Akcja',
                key: 'action',
                fixed: 'right',
                align: 'center',
                render: (text, record) => (
                    <Space size="middle">
                        <a><EditOutlined onClick={(e) => { this.actionEdit(record.u__id, e); }} /></a>
                        <a><DeleteOutlined onClick={(e) => { this.actionDelete(record.u__id, record.u__login, e); }} /></a>
                    </Space>
                ),
            },
        ];


        return (
            <div>
                <div style={{ float: 'right', paddingBottom: '12px' }}>
                <Space size="small">
                    <Button type="primary" icon={<PlusCircleOutlined />} onClick={this.actionAdd}>Dodaj</Button>
                    <Button type="default" icon={<ReloadOutlined />} onClick={this.actionReload}>Odśwież</Button>
                    </Space>
                </div>
                <div style={{ clear: 'both' }}>
                    <Table
                        size='small'
                        style={{cursor: 'pointer'}}
                        bordered
                        columns={columns}
                        rowKey={record => record.u__login}
                        dataSource={data}
                        pagination={pagination}
                        loading={loading}
                        onChange={this.handleTableChange}
                        scroll={{ x: 'max-content' }}
                        onRow={(record, rowIndex) => {
                            return {
                              onDoubleClick: (event) => {
                                  this.setState(
                                      {
                                        showUserEditDialog: true,
                                        currentUserId: record.u__id
                                      }
                                  );
                                }, // double click row
                            };
                          }}
                    />
                </div>
                {this.state.showUserEditDialog && <UserEditDialog onClose={this.onCloseUserEditDialog} userId={this.state.currentUserId} />}
            </div>
        );
    }
}

export default UsersPage;