import daoUser from './daoUser';

const auth = {
    authUserId: -1,
    authUser: null,
    authPassword: null,
    isAuthenticated: false,
    role: -1,
    authenticate(callback, _user, _password) {
        this.authUser = _user;
        this.authPassword = _password;
        const dao = new daoUser({ user: _user, password: _password});
        dao.getUserByLogin(_user)
            .then(data => { 
                if (data !== null && data.result !== undefined && data.result.login !== undefined) {
                    this.isAuthenticated = true;    
                    this.authUserId = data.result.id; 
                    this.role = data.result.role_id   
                }
                
                callback(data); 
            })
            .catch((error) => {
                callback({error: error.message}); 
            });
    },
    signout(callback) {
        this.isAuthenticated = false;
        this.authUser = null;
        this.authPassword = null;
        callback(); 
    }
}

export default auth;  