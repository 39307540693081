import postData from './dao'
import daoBase from './daoBase'

export default class daoCms extends daoBase {
    getPage(page) {
        return postData(daoBase.url+"/get_cms_page", { credentials: this.credentials, page: page });
    }

    storePage(page, html) {        
        return postData(daoBase.url+"/store_cms_page", { credentials: this.credentials, page: page, html: html });
    }
}