
import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Checkbox, Row, Col, Select, 
    InputNumber, Tabs, Space, Button, Image, List, Upload } from 'antd';
import { MinusCircleOutlined, PlusOutlined, FormOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import daoBase from '../api/daoBase';
import daoOffer from '../api/daoOffer';
import daoUser from '../api/daoUser';
import Uploader from '../components/Uploader';
import auth from '../api/auth';

export default function OfferEditDialog(props) {

    const [form] = Form.useForm();
    const [title, setTitle] = useState("Oferta");
    const [estateTypes, setEstateTypes] = useState([]);
    const [adTypes, setAdTypes] = useState([]);
    const [belongsTo, setBelongsTo] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [poviats, setPoviats] = useState([]);
    const [images, setImages] = useState([]);
    const [offerId, setOfferId] = useState(daoBase.genGuid());
    const [imageList, setImageList] = useState([]);
    const [mainImage, setMainImage] = useState();
    const [longDesc, setLongDesc] = useState();
    const [imagePreviewVisible, setImagePreviewVisible] = useState(false);
    const [captionPositions, setCaptionPositions] = useState([
        { id: 'lt', name: "Lewy grórny róg"},
        { id: 'lb', name: "Lewy dolny róg"},
        { id: 'rt', name: "Prawy grórny róg"},
        { id: 'rb', name: "Prawy dolny róg"}
    ]);

    const extraFieldCount = 15;
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const area_display_units = [
        {id: "m2", name: "m2"},
        {id: "a", name: "a"},
        {id: "ha", name: "ha"}
    ];

    let extraFieldsDef = [
        " ",
        "Działka",
        "Opis działki",
        "Charakterystyka przeznaczenia",
        "Dodatkowe informacje",
        "Cena za m2",
        "Opis budynku",
        "Położenie mieszkania",
        "Pomieszczenia",
        "Wykończenie lokalu",
        "Budynek",
        "Wykończenie budynku",
        "Forma własności",
        "Rynek",
        "Rodzaj zabudowy",
        "Winda",
        "Uzbrojenie terenu",
        "Typ budynku",
        "Typ mieszkania",
        "Rok budowy",
        "Balkon",
        "Okna",
        "Czynsz",
        "Ogrzewanie",
        "Technologia budowy",
        "Pokrycie dachu",
        "Dojazd",
        "Ogrodzenie",
        "@Adnotacja na ilustracji",
    ];

    useEffect(() => {
        getEstateTypes();
        getAdTypes();
        getAgents();
        getProvinces();

        if (props.offerId != -1) {
            setTitle(`Oferta nr ${props.offerId}`);
            setOfferId(props.offerId);

            const dao = new daoOffer();
            dao.getOffer(props.offerId)
                .then(data => {
                    if (data.error != undefined) {
                        Modal.error({ title: 'Błąd', content: data.error });
                        props.onClose('Error');
                    } else if (data.result == null) {
                        Modal.error({ title: 'Błąd', content: "Brak definicji oferty o id: " + props.offerId });
                        props.onClose('Error');
                    } else {
                        let extraFields = []; 
                        for (let i=1; i<=extraFieldCount; i++) {
                            if (data.result[`extra_data${i}`] == null || data.result[`extra_data${i}`] === '') {
                                continue;
                            }
                            const extraField = {
                                caption: data.result[`extra_data${i}_cap`] === '&nbsp;' ? ' ' : data.result[`extra_data${i}_cap`],
                                checked: data.result[`extra_data${i}_show_on_list`],
                                text: data.result[`extra_data${i}`], 
                            };
                            extraFields.push(extraField);       
                        }
                    
                        
                        form.setFieldsValue({
                            active: data.result.active === 1,
                            creator: data.result.creator,
                            modifier: data.result.modifier,
                            date_add: data.result.date_add,
                            date_upd: data.result.date_upd,
                            title: data.result.title,
                            short_description: data.result.short_description,
                            long_description: data.result.long_description,
                            agent_id: data.result.agent_id == null ? -1 : data.result.agent_id,
                            id_estate_type: data.result.id_estate_type,
                            id_ad_type: data.result.id_ad_type,
                            location1: data.result.location1,
                            location2: data.result.location2,
                            price: data.result.price,
                            old_price: data.result.old_price,
                            area: data.result.area,
                            area_display_unit: data.result.area_display_unit,
                            active: data.result.active == 1,
                            favourite: data.result.favourite == 1,
                            floor: data.result.floor,
                            floor_show_on_list: data.result.floor_show_on_list == 1,
                            rooms: data.result.rooms,
                            rooms_show_on_list: data.result.rooms_show_on_list == 1,
                            _extraFields: extraFields,
                            id_province: data.result.id_province,
                            id_poviat: data.result.id_poviat,
                            caption: data.result.caption,
                            caption_position: data.result.caption_position == null ? 'lt' : data.result.caption_position
                        }); 
                        setLongDesc(data.result.long_description);
                        setMainImage(data.result.default_img);
                        onProvinceChange(data.result.id_province);
                        setImages(data.result.imageList.images);
                        let arr = [];
                        for (let i in data.result.imageList.images) {
                            const img = data.result.imageList.images[i];
                            arr.push({
                                uid: `-${i}`,
                                name: img,
                                status: 'done',
                                url: daoBase.url+"/get_offer_image?fileName=full." + img + "&offerId=" + props.offerId,
                            });
                        }
                        setImageList(arr);
                        //console.log(data.imageList.images);
                    }
                })
                .catch((error) => {
                    Modal.error({ title: 'Błąd', content: error.Message });
                    props.onClose('Error');
                });
        } else {
            setTitle("Nowa oferta");    
            form.setFieldsValue({
                agent_id: -1, 
                area_display_unit: "m2",
                caption_position: 'lt'
            });              
        }
    }, [props.offerId]);

    const onProvinceChange = (e) => {
        const dao = new daoOffer();
        dao.getPoviats(e)
            .then(data => {
                if (data != null) {
                    setPoviats(data);
                } else {
                    Modal.error({ title: 'Błąd', content: data.error });
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.message });
            });
    }

    const getEstateTypes = () => {
        const dao = new daoOffer();
        dao.getEstateTypes()
            .then(data => {
                if (data != null) {
                    //estateTypes = data;
                    setEstateTypes(data);
                } else {
                    Modal.error({ title: 'Błąd', content: data.error });
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.message });
            });
    }

    const getAdTypes = () => {
        const dao = new daoOffer();
        dao.getAdTypes()
            .then(data => {
                if (data != null) {
                    setAdTypes(data);
                } else {
                    Modal.error({ title: 'Błąd', content: data.error });
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.message });
            });
    }

    const getAgents = () => {
        const dao = new daoUser();
        dao.getAgents()
            .then(data => {
                if (data != null) {
                    data.unshift({ id: -1, full_name: ' - brak -'});
                    setBelongsTo(data);              
                } else {
                    Modal.error({ title: 'Błąd', content: data.error });
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.message });
            });
    }

    const getProvinces = () => {
        const dao = new daoOffer();
        dao.getProvinces()
            .then(data => {
                if (data != null) {
                    setProvinces(data);
                } else {
                    Modal.error({ title: 'Błąd', content: data.error });
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.message });
            });
    }
    
    const onCancel = (e) => {
        props.onClose('handleCancel');
    }

    const onStore = (values) => {
        const dao = new daoOffer();
        const ld = values.long_description == undefined ? longDesc : values.long_description;

        const offer = {
            id_estate_type: values.id_estate_type,
            id_ad_type: values.id_ad_type,
            title: values.title,
            short_description: values.short_description,
            long_description: ld == null ? '' : ld,
            location1: values.location1,
            location2: values.location2,
            user_id: auth.authUserId,
            active: values.active ? 1 : 0,
            id_category: 1,
            default_img: mainImage,
            price: values.price,
            area: values.area,
            area_display_unit: values.area_display_unit,
            id_province: values.id_province,
            id_poviat: values.id_poviat,
            id_company: 3,
            favourite: values.favourite ? 1 : 0,
            floor: values.floor,
            rooms: values.rooms,
            agent_id: values.agent_id,
            floor_show_on_list: values.floor_show_on_list ? 1 : 0,
            rooms_show_on_list: values.rooms_show_on_list ? 1 : 0,
            old_price: values.old_price,
            caption: values.caption,
            caption_position: values.caption_position,
            guid: offerId
        };

        let k = 1;
        for (let i in values._extraFields) {
            const ef = values._extraFields[i];
            if (ef.text != null && ef.text !== '' && ef.caption != null && ef.caption !== '' && ef.caption !== ' ') {
                offer[`extra_data${k}`] = ef.text;
                offer[`extra_data${k}_cap`] = ef.caption;
                offer[`extra_data${k}_show_on_list`] = ef.checked ? 1 : 0; 
                k++;        
            }   
        }
        for (let i=k; i<=15; i++) {
            offer[`extra_data${i}`] = '';
            offer[`extra_data${i}_cap`] = '';
            offer[`extra_data${i}_show_on_list`] = 0;
        }

        console.log(offer);

        if (props.offerId == -1) {            
            dao.insertOffer(offer).then(data => { 
                if (data.error != undefined) {
                    Modal.error({ title: 'Błąd', content: data.error });
                }  else {
                    props.onClose('Success');
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.Message });
            });
        } else {
            offer.id = props.offerId;
            dao.updateOffer(offer).then(data => { 
                if (data.error != undefined) {
                    Modal.error({ title: 'Błąd', content: data.error });
                }  else {
                    props.onClose('Success');
                }
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.Message });
            });
        }
    }

    const onCancelImagePreview = () => setImagePreviewVisible(false);

    return (
        <Modal title={title} visible='true' okText="Zapisz" width={1100}
            cancelText="Anuluj"
            onCancel={onCancel}
            centered={true}
            maskClosable={false}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        onStore(values);
                        //form.resetFields();
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form form={form} initialValues={{
                lp: 999,
            }}>
                <Tabs defaultActiveKey="1" type="card" tabBarGutter={6} size='small' style={{ height: 655, paddingTop: 0, marginBottom: 0, marginTop: 0 }}>
                    <Tabs.TabPane tab="Podstawowe" key="1" forceRender={true} style={{ paddingTop: 10 }}>
                        <Row>
                            <Col span={12}>
                                <Form.Item label="Data utworzenia / utworzył" labelCol={{ span: 8 }} style={{ marginBottom: 0 }} >
                                    <Form.Item name="date_add"
                                        style={{ display: 'inline-block', width: '100px' }}>
                                        <Input readonly='readonly' />
                                    </Form.Item>
                                    <Form.Item name="creator"
                                        style={{ display: 'inline-block', width: '240px', marginLeft: '10px' }}>
                                        <Input readonly='readonly' />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label="Data zmiany / zmienił" labelCol={{ span: 8 }} style={{ marginBottom: 0 }} >
                                    <Form.Item name="date_upd"
                                        style={{ display: 'inline-block', width: '100px' }}>
                                        <Input readonly='readonly' />
                                    </Form.Item>
                                    <Form.Item name="modifier"
                                        style={{ display: 'inline-block', width: '240px', marginLeft: '10px' }}>
                                        <Input readonly='readonly' />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label="Rodzaj nieruchomości" name="id_estate_type" labelCol={{ span: 8 }}
                                    rules={[{ required: true, message: 'Wybierz rodzaj nieruchomości!' }]}>
                                    <Select 
                                        showSearch 
                                        optionFilterProp="children" 
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                                        {estateTypes.map(estateType => (
                                            <Select.Option key={estateType.id_estate_type} value={estateType.id_estate_type}>{estateType.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Rodzaj ogłoszenia" name="id_ad_type" labelCol={{ span: 8 }}
                                    rules={[{ required: true, message: 'Wybierz rodzaj ogłoszenia!' }]}>
                                    <Select
                                        showSearch 
                                        optionFilterProp="children" 
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>                                                                        
                                        {adTypes.map(adType => (
                                            <Select.Option key={adType.id_ad_type} value={adType.id_ad_type}>{adType.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Województwo" name="id_province" labelCol={{ span: 8 }}
                                    rules={[{ required: true, message: 'Wybierz województwo!' }]}>
                                    <Select
                                        onChange={onProvinceChange}                                          
                                        showSearch 
                                        optionFilterProp="children" 
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                                        {provinces.map(province => (
                                            <Select.Option key={province.id} value={province.id}>{province.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Powiat" name="id_poviat" labelCol={{ span: 8 }}
                                    rules={[{ required: true, message: 'Wybierz powiat!' }]}>
                                    <Select                                      
                                        showSearch 
                                        optionFilterProp="children" 
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                                        {poviats.map(poviat => (
                                            <Select.Option key={poviat.id} value={poviat.id}>{poviat.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Lokalizacja" name="location1" labelCol={{ span: 8 }}
                                    rules={[{ required: true, message: 'Podaj lokalizację!' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Współrzędne GPS" name="location2" labelCol={{ span: 8 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Przypisana do" name="agent_id" labelCol={{ span: 6 }}>
                                    <Select                                        
                                        showSearch                                     
                                        optionFilterProp="children" 
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>

                                        {belongsTo.map(agent => (
                                            <Select.Option key={agent.id} value={agent.id}>{agent.full_name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Cena [PLN]" labelCol={{ span: 6 }} style={{ marginBottom: 0 }} >
                                    <Form.Item name="price"
                                        style={{ display: 'inline-block', width: '100px' }}>
                                        <InputNumber min={0} max={99999999} />
                                    </Form.Item>
                                    <span style={{ display: 'inline-block', width: '150px', marginLeft: '12px', marginTop: '6px' }}> Stara cena [PLN]:</span>
                                    <Form.Item name="old_price"
                                        style={{ display: 'inline-block', width: '110px', margin: '0 8px' }}>
                                        <InputNumber min={0} max={99999999} />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label="Powierzchnia [m2]" labelCol={{ span: 6 }} style={{ marginBottom: 0 }} >
                                    <Form.Item name="area"
                                        style={{ display: 'inline-block', width: '100px' }}>
                                        <InputNumber min={0} max={99999999} />
                                    </Form.Item>
                                    <span style={{ display: 'inline-block', width: '150px', marginLeft: '12px', marginTop: '6px' }}> Wyświetlaj w jednostce: </span>
                                    <Form.Item name="area_display_unit"
                                        style={{ display: 'inline-block', width: '90px', margin: '0 8px' }}>                                        
                                        <Select 
                                            showSearch 
                                            optionFilterProp="children" 
                                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                                            {area_display_units.map(adu => (
                                                <Select.Option key={adu.id} value={adu.id}>{adu.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label="Piętro" labelCol={{ span: 6 }} style={{ marginBottom: 0 }} >
                                    <Form.Item name="floor"
                                        style={{ display: 'inline-block', width: '90px' }}>
                                        <InputNumber min={0} max={30} />
                                    </Form.Item>
                                    <Form.Item name="floor_show_on_list" valuePropName='checked'
                                        style={{ display: 'inline-block', width: '250px', marginLeft: '20px' }}>
                                        <Checkbox>Pokaż w widoku listy</Checkbox>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label="Ilość pokoi" labelCol={{ span: 6 }} style={{ marginBottom: 0 }} >
                                    <Form.Item name="rooms"
                                        style={{ display: 'inline-block', width: '90px' }}>
                                        <InputNumber min={0} max={100} />
                                    </Form.Item>
                                    <Form.Item name="rooms_show_on_list" valuePropName='checked'
                                        style={{ display: 'inline-block', width: '250px', marginLeft: '20px' }}>
                                        <Checkbox>Pokaż w widoku listy</Checkbox>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label="  " colon={false} labelCol={{ span: 6 }} style={{ marginBottom: 0 }} >
                                    <Form.Item name="active" valuePropName='checked'
                                        style={{ display: 'inline-block', width: '90px' }}>
                                        <Checkbox>Aktywna</Checkbox>
                                    </Form.Item>
                                    <Form.Item name="favourite" valuePropName='checked'
                                        style={{ display: 'inline-block', width: '250px', marginLeft: '20px' }}>
                                        <Checkbox>Wyróżniona</Checkbox>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label=" " colon={false} labelCol={{ span: 6 }}>
                                    {mainImage != null && <img onClick={() => setImagePreviewVisible(true)} 
                                        style={{maxHeight: 88, border: '1px solid #e5e5e5', cursor: 'pointer' }} 
                                        src={daoBase.url + '/get_offer_image?fileName=thumb.' + mainImage + '&offerId=' + offerId} />}
                                </Form.Item>
                                <Modal
                                    visible={imagePreviewVisible}
                                    title={mainImage}
                                    footer={null}
                                    onCancel={onCancelImagePreview}
                                    width={900}
                                    centered={true}
                                >
                                    <Row>
                                        <Col style={{ width: '100%', overflow: 'auto', maxHeight: 600 }} >
                                            <img style={{width: '100%' }} src={daoBase.url + "/get_offer_image?fileName=full."+mainImage+"&offerId="+offerId} />
                                        </Col>
                                    </Row>                                    
                                </Modal>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Etykieta" name="caption" labelCol={{ span: 8 }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Pozycja etykiety" name="caption_position" labelCol={{ span: 6 }}>
                                    <Select 
                                        showSearch 
                                        optionFilterProp="children" 
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }>
                                        {captionPositions.map(captionPos => (
                                            <Select.Option key={captionPos.id} value={captionPos.id}>{captionPos.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Tytuł ofety" name="title" labelCol={{ span: 4 }}
                                    rules={[{ required: true, message: 'Podaj tytuł!' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Nazwa nieruchomości" name="short_description" labelCol={{ span: 4 }} style={{ marginBottom: 0 }}
                                    rules={[{ required: true, message: 'Podaj nazwę nieruchomości!' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Informacje dodatkowe" forceRender={true} key="2" style={{ paddingTop: 10 }}>
                    <div style={{ overflow: 'auto', height: 540, paddingRight: 8}}>
                        <Form.List name="_extraFields">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(field => (
                                        <Space key={field.key} style={{ display: 'flex', marginBottom: 0 }} align="top">
                                            <Row>
                                                <Col span={8} >
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate={(prevValues, curValues) =>
                                                            prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                        }
                                                    >
                                                        {() => (
                                                            <Form.Item
                                                                {...field}
                                                                name={[field.name, 'caption']}
                                                                fieldKey={[field.fieldKey, 'caption']}
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                <Select style={{ width: 320 }} showSearch>
                                                                    {extraFieldsDef.map(item => (
                                                                        <Select.Option key={item} value={item}>
                                                                            {item}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        )}
                                                    </Form.Item>

                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'checked']}
                                                        fieldKey={[field.fieldKey, 'checked']}
                                                        valuePropName='checked'
                                                    >
                                                        <Checkbox>Pokaż w widoku listy</Checkbox>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={16} style={{ display: 'flex' }} >
                                                    <Form.Item
                                                        {...field}
                                                        name={[field.name, 'text']}
                                                        fieldKey={[field.fieldKey, 'text']}
                                                        style={{ marginRight: '8px' }}
                                                    >
                                                        <Input.TextArea cols={110} rows={3} />
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                </Col>
                                            </Row>
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        {(fields.length < extraFieldCount) && <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Dodaj pole z informacjami
                                        </Button>}
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Pełna treść" key="3"  style={{ paddingTop: 10 }}>
                        <Form.Item name="long_description" labelCol={{ span: 8 }}>
                            <ReactQuill theme="snow" style={{height: '500px'}} />                                               
                        </Form.Item>                                                                       
                    </Tabs.TabPane>                    
                    
                    <Tabs.TabPane tab="Ilustracje" key="4" style={{ paddingTop: 10 }}>
                        <Uploader fileList={imageList} offerId={offerId} login={auth.authUser} password={auth.authPassword} mainImage={mainImage} callback={(e) => setMainImage(e.mainImage)}/>
                    </Tabs.TabPane>
                </Tabs>
            </Form>
        </Modal>
    );
}
