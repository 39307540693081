import React, { useState, useEffect, useRef } from 'react';
import { Modal, Space, Button } from 'antd';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation } from 'react-router-dom';
import daoCms from '../api/daoCms';
import {   SaveOutlined , ReloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import JoditEditor from "jodit-react";


export default function CmsPage(props) {
    const editor = useRef(null)
    const location = useLocation();
    const [htmlContent, setHtmlContent] = React.useState();
    
    
    useEffect(() => {
        //registerHr();
        loadPage();
    }, [location.pathname]);

    const actionStore = (e) => {
        Modal.confirm({
            title: 'Czy na pewno zapisać wprowadzone zmiany?',
            icon: <ExclamationCircleOutlined />,
            content: 'Pytanie',
            okText: 'Tak',
            cancelText: 'Nie',
            onOk() {
                const dao = new daoCms();
                const arr = location.pathname.split('/');
                if (arr.length > 1) {
                    dao.storePage(arr[arr.length-1], htmlContent)
                    .then(data => { 
                        if (data.error != undefined) {
                            Modal.error({ title: 'Błąd', content: data.error });                       
                        } else {
                            //Modal.info({title: 'Informacja', content: 'Pomyślnie zapisano wprowadzone zmiany.'})
                        }                
                    })
                    .catch((error) => {
                        Modal.error({ title: 'Błąd', content: error.Message }); 
                    });
                } else {
                    Modal.error({ title: 'Błąd',  content: 'Nieprawidłowa ścieżka wywołania!' }); 
                }
            },
            onCancel() {              
            },
          });

        
    }

    const loadPage = () => {
        const dao = new daoCms();
        const arr = location.pathname.split('/');
        if (arr.length > 1) {
            dao.getPage(arr[arr.length-1])
            .then(data => { 
                if (data.error != undefined) {
                    Modal.error({ title: 'Błąd', content: data.error });                       
                } else {
                    setHtmlContent(data.html);
                }                
            })
            .catch((error) => {
                Modal.error({ title: 'Błąd', content: error.Message }); 
            });
        } else {
            Modal.error({ title: 'Błąd',  content: 'Nieprawidłowa ścieżka wywołania!' }); 
        }
    }

    const actionReload = (e) => {
        e.preventDefault();
        loadPage();
    }
/*
    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'color', 'hr',
      ]
*/
    const config = {
        language: "pl",
        height: (window.innerHeight - 155),
    }

    const onEditorBlur = (e) => {
        try { 
            setHtmlContent(e.target.innerHTML) 
        } catch(error) {
            console.log(error) 
        } 
        //onBlur={onEditorBlur} 
    }

    return (
            <div>   
                <div style={{ float: 'right', paddingBottom: '12px' }}>
                <Space size="small">
                    <Button type="primary" icon={<SaveOutlined />} onClick={actionStore}>Zapisz</Button>
                    <Button type="default" icon={<ReloadOutlined />} onClick={actionReload}>Odśwież</Button>
                    </Space>
                </div>
                <div style={{ clear: 'both' }}>
                <JoditEditor
                    ref={editor}
                    config={config} 
                    value={htmlContent}
                    onBlur={onEditorBlur} 
                 />
                </div>
            </div>


    );
}